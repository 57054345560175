import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getPurchase = ({ postsPerPage, startDate = "", endDate = "", supplier = "" }) =>
  axiosInstance.get(
    `api/v1/purchase/purchase-master?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}`
  );

//obtaining all fiscal sessions
export const getAllPurchase = () => axiosInstance.get(`api/v1/purchase/purchase-master?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPagePurchase = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/purchase/purchase-master?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createPurchase = (body) => axiosInstance.post(`api/v1/purchase/purchase-master`, body);
//updating function
export const updatePurchase = (id, body) => axiosInstance.patch(`api/v1/purchase/purchase-master/${id}`, body);

//searching function
export const handleSearch = ({ search, postsPerPage, startDate = "", endDate = "", supplier = "" }) =>
  axiosInstance.get(
    `api/v1/purchase/purchase-master?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&supplier=${supplier}`
  );
