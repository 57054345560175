import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";
import { v4 as uuidv4 } from "uuid";

// get customer order
export const getCustomerOrder = createAsyncThunk(
  "customerOrder/getCustomerOrder",
  async ({ postsPerPage, startDate, endDate, customer, status }, { rejectWithValue }) => {
    try {
      const { data } = await API.getCustomerOrder({ postsPerPage, startDate, endDate, customer, status });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const getAllCustomerOrder = createAsyncThunk(
  "customerOrder/getAllCustomerOrder",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllCustomerOrder(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get previous
export const getPrevious = createAsyncThunk("customerOrder/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("customerOrder/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageCustomerOrder = createAsyncThunk(
  "customerOrder/getPageCustomerOrder",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageCustomerOrder(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create CustomerOrder
export const createCustomerOrder = createAsyncThunk(
  "customerOrder/createCustomerOrder",
  async (data, { rejectWithValue }) => {
    const {
      orderNo,
      customer,
      shippingMode,
      quality,
      design,
      destination,
      deliveryDate,
      carpetType,
      length,
      unit,
      breadth,
      ratePerMeter,
      quantity,
      weavingDays,
      rawMaterial,
    } = data;
    try {
      const body = JSON.stringify({
        orderNo,
        customer,
        shippingMode,
        quality,
        design,
        destination,
        deliveryDate,
        carpetType,
        length,
        unit,
        breadth,
        ratePerMeter,
        quantity,
        weavingDays,
        rawMaterial,
      });
      const { data } = await API.createCustomerOrder(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateCustomerOrder = createAsyncThunk(
  "customerOrder/updateCustomerOrder",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const {
      orderNo,
      customer,
      shippingMode,
      quality,
      design,
      destination,
      deliveryDate,
      carpetType,
      length,
      unit,
      breadth,
      ratePerMeter,
      quantity,
      weavingDays,
      rawMaterial,
    } = values;
    try {
      const body = JSON.stringify({
        orderNo,
        customer,
        shippingMode,
        quality,
        design,
        destination,
        deliveryDate,
        carpetType,
        length,
        unit,
        breadth,
        ratePerMeter,
        quantity,
        weavingDays,
        rawMaterial,
      });
      const { data } = await API.updateCustomerOrder(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get specific customer order
export const getSpecificCustomerOrder = createAsyncThunk(
  "customerOrder/getSpecificCustomerOrder",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificCustomerOrder(id);

      if (data?.rawMaterial?.length > 0) {
        const updatedCustomerOrder = data?.rawMaterial.map(async (detail) => {
          const { data: stockData } = await API.itemStockQuantity(detail?.item?.id, detail?.colorCode);
          if (stockData?.results?.length > 0) {
            return {
              ...detail,
              item: detail?.item,
              colorCode: detail?.colorCode,
              type: detail?.type,
              available: stockData?.results[0]?.quantity || "",
              quantity: detail?.quantity,
              unique: uuidv4(),
            };
          } else {
            return {
              ...detail,
              item: detail?.item,
              colorCode: detail?.colorCode,
              type: detail?.type,
              available: "",
              quantity: detail?.quantity,
              unique: uuidv4(),
            };
          }
        });
        const updatedDetails = await Promise.all(updatedCustomerOrder);
        return { ...data, rawMaterial: updatedDetails };
      } else {
        return data;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get specific design composition
export const getSpecificDesignComposition = createAsyncThunk(
  "customerOrder/getSpecificDesignComposition",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificDesignComposition(id);

      if (data?.designDetails?.length > 0) {
        const updatedDetailsPromises = data.designDetails.map(async (detail) => {
          const { data: stockData } = await API.itemStockQuantity(detail?.item?.id, detail?.colorCode);
          if (stockData?.results?.length > 0) {
            return {
              ...detail,
              item: detail?.item,
              type: detail?.type,
              colorCode: detail?.colorCode,
              available: stockData?.results[0]?.quantity || "",
              quantity: "",
              unique: uuidv4(),
            };
          } else {
            return {
              ...detail,
              item: detail?.item,
              type: detail?.type,
              colorCode: detail?.colorCode,
              available: "",
              quantity: "",
              unique: uuidv4(),
            };
          }
        });

        const updatedDetails = await Promise.all(updatedDetailsPromises);
        return updatedDetails;
      }
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// handle search
export const customerOrderCancel = createAsyncThunk(
  "customerOrder/customerOrderCancel",
  async (data, { rejectWithValue }) => {
    const { id, cancelReason } = data;
    try {
      const body = JSON.stringify({ status: "CANCELLED", cancelReason });
      const { data } = await API.customerOrderCancel(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "customerOrder/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, customer, status, search });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
