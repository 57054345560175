import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get purchaseReturn
export const getPurchaseReturn = createAsyncThunk(
  "purchaseReturn/getPurchaseReturn",
  async ({ postsPerPage, startDate, endDate, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.getPurchaseReturn({ postsPerPage, startDate, endDate, supplier });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all purchaseReturn
export const getAllPurchaseReturn = createAsyncThunk(
  "purchaseReturn/getAllPurchaseReturn",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllPurchaseReturn();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("purchaseReturn/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("purchaseReturn/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPagePurchaseReturn = createAsyncThunk(
  "purchaseReturn/getPagePurchaseReturn",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPagePurchaseReturn(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create purchaseReturn
export const createPurchaseReturn = createAsyncThunk(
  "purchaseReturn/createPurchaseReturn",
  async (data, { rejectWithValue }) => {
    const { purchase, returnReason, remarks, returnDetails } = data;
    try {
      const body = JSON.stringify({ purchase, returnReason, remarks, returnDetails });
      const { data } = await API.createPurchaseReturn(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

export const getPurchaseDetails = createAsyncThunk(
  "purchaseReturn/getPurchaseDetails",
  async (purchase, { rejectWithValue }) => {
    try {
      const { data } = await API.getPurchaseDetails(purchase);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "purchaseReturn/handleSearch",
  async ({ search, postsPerPage, startDate, endDate, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ search, postsPerPage, startDate, endDate, supplier });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
