import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getCustomer, getNext, createCustomer, updateCustomer, handleSearch } from "./thunk";

const initialState = {
  customers: [],
  edit: false,
  customer: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingCustomer: false,
  loadingNext: false,
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    customerEditSuccess: (state, action) => {
      state.edit = true;
      state.customer = state.customers.find((customer) => customer.id === action.payload);
    },
    clearAllCustomer: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingCustomer = false;
      state.customer = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.customers = [...state.customers, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createCustomer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCustomer.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createCustomer.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateCustomer.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateCustomer.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateCustomer.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getCustomer.pending, handleSearch.pending), (state) => {
      state.loadingCustomer = true;
    });
    builder.addMatcher(isAnyOf(getCustomer.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingCustomer = false;
      state.customers = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getCustomer.rejected, handleSearch.rejected), (state) => {
      state.loadingCustomer = false;
    });
  },
});

export const { customerEditSuccess, clearAllCustomer } = customerSlice.actions;

export default customerSlice.reducer;
