import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get receivableReport
export const getReceivableReport = createAsyncThunk(
  "receivableReport/getReceivableReport",
  async ({ postsPerPage, startDate, endDate, customer, dyeingHouse, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.getReceivableReport({
        postsPerPage,
        startDate,
        endDate,
        customer,
        dyeingHouse,
        supplier,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all receivableReport
export const getAllReceivableReport = createAsyncThunk(
  "receivableReport/getAllReceivableReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllReceivableReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("receivableReport/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("receivableReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageReceivableReport = createAsyncThunk(
  "receivableReport/getPageReceivableReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageReceivableReport(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "receivableReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, dyeingHouse, supplier, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({
        search,
        postsPerPage,
        startDate,
        endDate,
        customer,
        dyeingHouse,
        supplier,
      });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
