import { useSelector } from "react-redux";
import { Toaster } from "sonner";
import "./App.css";
import "tippy.js/dist/tippy.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "react-loading-skeleton/dist/skeleton.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-color-palette/css";
import PublicRoute from "./Routes/PublicRoute";
import PrivateRoute from "./Routes/PrivateRoute";
import Layout from "./Components/Layout";

const App = () => {
  const { isAuthenticated } = useSelector((state) => state?.auth);

  return (
    <div style={{ overflow: "hidden" }}>
      {isAuthenticated ? (
        <Layout>
          <PrivateRoute />
        </Layout>
      ) : (
        <PublicRoute />
      )}
      <Toaster
        position="top-right"
        duration={1500}
        invert
        richColors
        closeButton
        pauseWhenPageIsHidden
        visibleToasts={5}
        toastOptions={{
          classNames: {
            closeButton: "toast-close-btn overflow-hidden",
          },
          className: "toast-sonner",
        }}
      />
    </div>
  );
};

export default App;
