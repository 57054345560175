import axiosInstance from "../../../Utils/axios";

// get paginated data
export const getDyeing = ({
  postsPerPage,
  startDate = "",
  endDate = "",
  customer = "",
  status = "",
  dyeingHouse = "",
  isPaid = "",
}) =>
  axiosInstance.get(
    `api/v1/production/dye?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}&dyeing_house=${dyeingHouse}&is_Paid=${isPaid}`
  );

//obtaining all data
export const getAllDyeing = () => axiosInstance.get(`api/v1/production/dye?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageDyeing = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/production/dye?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

// create function
export const createDirectDyeing = (body) => axiosInstance.post(`api/v1/production/dye`, body);
// update function
export const updateDirectDyeing = (id, body) => axiosInstance.patch(`api/v1/production/dye/${id}`, body);
// get specific customer order details
export const getSpecificDyeing = (id) => axiosInstance.get(`api/v1/production/dye/${id}`);

// get raw materials of specific dyeing
export const getSpecificRawMaterials = (id) =>
  axiosInstance.get(`api/v1/order/raw-materials?limit=0&customer_order=${id}&type=NON_NATURAL`);
// cancel customer order
export const dyeingCancel = (id, body) => axiosInstance.patch(`api/v1/production/cancel-dye/${id}`, body);

//searching function
export const handleSearch = ({
  search,
  postsPerPage,
  startDate = "",
  endDate = "",
  customer = "",
  status = "",
  isPaid = "",
}) =>
  axiosInstance.get(
    `api/v1/production/dye?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&customer=${customer}&status=${status}&is_Paid=${isPaid}`
  );

export const dyeVerification = (id, body) => axiosInstance.patch(`api/v1/production/dye-verification/${id}`, body);

export const getVefifiedDetail = (id) => axiosInstance.get(`/api/v1/production/dye-verified-details/${id}`);
export const makePayment = (body) => axiosInstance.post(`/api/v1/production/dye-master-payment`, body);
