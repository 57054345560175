import { createSlice } from "@reduxjs/toolkit";
import {
  getNumericData,
  getNumericFinanceData,
  getPurchaseLineChartData,
  getSaleLineChartData,
  getDyeingLineChartData,
  getWeavingLineChartData,
} from "./thunk";

const initialState = {
  // dyeing data
  loadingNumericaData: false,
  totalPendingDyeing: "",
  totalPartiallyCompletedDyeing: "",
  totalCompletedDyeing: "",
  totalTerminatedDyeing: "",
  // weaving data
  totalPendingWeaving: "",
  totalReceivedWeaving: "",
  totalCancelledWeaving: "",
  // purchase data
  totalPurchasedOrder: "",
  totalPurchasedReturn: "",
  // finance data
  loadingFinanceData: false,
  totalPayableAmount: "",
  totalRecievableAmount: "",
  // purchase line chart
  loadingPurchaseLineChart: false,
  purchaseLineChart: [],
  // sale line chart
  loadingSaleLineChart: false,
  saleLineChart: [],
  // dyeing line chart
  loadingDyeingLineChart: false,
  dyeingLineChart: [],
  // weaving line chart
  loadingWeavingLineChart: false,
  weavingLineChart: [],
};

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNumericData.pending, (state) => {
      state.loadingNumericaData = true;
    });
    builder.addCase(getNumericData.fulfilled, (state, action) => {
      const {
        totalPendingDyeing,
        totalPartiallyCompletedDyeing,
        totalCompletedDyeing,
        totalTerminatedDyeing,
        totalPendingWeaving,
        totalReceivedWeaving,
        totalCancelledWeaving,
        totalPurchasedOrder,
        totalPurchasedReturn,
      } = action.payload?.results[0];
      state.loadingNumericaData = false;
      state.totalPendingDyeing = totalPendingDyeing;
      state.totalPartiallyCompletedDyeing = totalPartiallyCompletedDyeing;
      state.totalCompletedDyeing = totalCompletedDyeing;
      state.totalTerminatedDyeing = totalTerminatedDyeing;
      state.totalPendingWeaving = totalPendingWeaving;
      state.totalReceivedWeaving = totalReceivedWeaving;
      state.totalCancelledWeaving = totalCancelledWeaving;
      state.totalPurchasedOrder = totalPurchasedOrder;
      state.totalPurchasedReturn = totalPurchasedReturn;
    });
    builder.addCase(getNumericData.rejected, (state) => {
      state.loadingNumericaData = false;
      state.totalPendingDyeing = "";
      state.totalPartiallyCompletedDyeing = "";
      state.totalCompletedDyeing = "";
      state.totalTerminatedDyeing = "";
      state.totalPendingWeaving = "";
      state.totalReceivedWeaving = "";
      state.totalCancelledWeaving = "";
      state.totalPurchasedOrder = "";
      state.totalPurchasedReturn = "";
    });
    builder.addCase(getNumericFinanceData.pending, (state) => {
      state.loadingFinanceData = true;
    });
    builder.addCase(getNumericFinanceData.fulfilled, (state, action) => {
      const { totalPayableAmount, totalRecievableAmount } = action.payload?.results[0];
      state.loadingFinanceData = false;
      state.totalPayableAmount = totalPayableAmount;
      state.totalRecievableAmount = totalRecievableAmount;
    });
    builder.addCase(getNumericFinanceData.rejected, (state) => {
      state.loadingFinanceData = false;
      state.totalPayableAmount = "";
      state.totalRecievableAmount = "";
    });
    builder.addCase(getPurchaseLineChartData.pending, (state) => {
      state.loadingPurchaseLineChart = true;
    });
    builder.addCase(getPurchaseLineChartData.fulfilled, (state, action) => {
      state.loadingPurchaseLineChart = false;
      state.purchaseLineChart = action.payload;
    });
    builder.addCase(getPurchaseLineChartData.rejected, (state) => {
      state.loadingPurchaseLineChart = false;
      state.purchaseLineChart = [];
    });
    builder.addCase(getSaleLineChartData.pending, (state) => {
      state.loadingSaleLineChart = true;
    });
    builder.addCase(getSaleLineChartData.fulfilled, (state, action) => {
      state.loadingSaleLineChart = false;
      state.saleLineChart = action.payload;
    });
    builder.addCase(getSaleLineChartData.rejected, (state) => {
      state.loadingSaleLineChart = false;
      state.saleLineChart = [];
    });
    builder.addCase(getDyeingLineChartData.pending, (state) => {
      state.loadingDyeingLineChart = true;
    });
    builder.addCase(getDyeingLineChartData.fulfilled, (state, action) => {
      state.loadingDyeingLineChart = false;
      state.dyeingLineChart = action.payload;
    });
    builder.addCase(getDyeingLineChartData.rejected, (state) => {
      state.loadingDyeingLineChart = false;
      state.dyeingLineChart = [];
    });
    builder.addCase(getWeavingLineChartData.pending, (state) => {
      state.loadingWeavingLineChart = true;
    });
    builder.addCase(getWeavingLineChartData.fulfilled, (state, action) => {
      state.loadingWeavingLineChart = false;
      state.weavingLineChart = action.payload;
    });
    builder.addCase(getWeavingLineChartData.rejected, (state) => {
      state.loadingWeavingLineChart = false;
      state.weavingLineChart = [];
    });
  },
});

export const {} = dashboardSlice.actions;

export default dashboardSlice.reducer;
