import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPayableReport, getNext, handleSearch } from "./thunk";

const initialState = {
  payables: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingPayable: false,
};

export const payableReportSlice = createSlice({
  name: "payableReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.payables = [...state.payables, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getPayableReport.pending, handleSearch.pending), (state) => {
      state.loadingPayable = true;
    });
    builder.addMatcher(isAnyOf(getPayableReport.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingPayable = false;
      state.payables = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getPayableReport.rejected, handleSearch.rejected), (state) => {
      state.loadingPayable = false;
    });
  },
});

export const {} = payableReportSlice.actions;

export default payableReportSlice.reducer;
