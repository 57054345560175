import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get purchase
export const getPurchase = createAsyncThunk(
  "purchase/getPurchase",
  async ({ postsPerPage, startDate, endDate, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.getPurchase({ postsPerPage, startDate, endDate, supplier });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all purchase
export const getAllPurchase = createAsyncThunk("purchase/getAllPurchase", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllPurchase();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("purchase/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("purchase/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPagePurchase = createAsyncThunk("purchase/getPagePurchase", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPagePurchase(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create purchase
export const createPurchase = createAsyncThunk("purchase/createPurchase", async (data, { rejectWithValue }) => {
  const { supplier, purchaseDetails, paymentMethod, billNo, remarks } = data;
  try {
    const body = JSON.stringify({ supplier, purchaseDetails, billNo, paymentMethod, remarks });
    const { data } = await API.createPurchase(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updatePurchase = createAsyncThunk("purchase/updatePurchase", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { supplier, purchaseDetails, paymentMethod, remarks } = values;
  try {
    const body = JSON.stringify({ supplier, purchaseDetails, paymentMethod, remarks });
    const { data } = await API.updatePurchase(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk(
  "purchase/handleSearch",
  async ({ postsPerPage, startDate, endDate, supplier, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, supplier, search });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
