import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getSupplier,
  getNext,
  createSupplier,
  updateSupplier,
  handleSearch,
} from "./thunk";

const initialState = {
  suppliers: [],
  edit: false,
  supplier: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingSupplier: false,
  loadingNext: false,
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    supplierEditSuccess: (state, action) => {
      state.edit = true;
      state.supplier = state.suppliers.find((supplier) => supplier.id === action.payload);
    },
    clearAllSupplier: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingSupplier = false;
      state.supplier = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.suppliers = [...state.suppliers, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createSupplier.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSupplier.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createSupplier.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateSupplier.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateSupplier.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateSupplier.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getSupplier.pending, handleSearch.pending), (state) => {
      state.loadingSupplier = true;
    });
    builder.addMatcher(isAnyOf(getSupplier.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingSupplier = false;
      state.suppliers = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getSupplier.rejected, handleSearch.rejected), (state) => {
      state.loadingSupplier = false;
    });
  },
});

export const { supplierEditSuccess, clearAllSupplier } = supplierSlice.actions;

export default supplierSlice.reducer;
