import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get weavingReport
export const getWeavingReport = createAsyncThunk(
  "weavingReport/getWeavingReport",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.getWeavingReport({ postsPerPage, startDate, endDate, customer, status, supplier });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all weavingReport
export const getAllWeavingReport = createAsyncThunk(
  "weavingReport/getAllWeavingReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllWeavingReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("weavingReport/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("weavingReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageWeavingReport = createAsyncThunk(
  "weavingReport/getPageWeavingReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageWeavingReport(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "weavingReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ search, postsPerPage, startDate, endDate, customer, status, supplier });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
