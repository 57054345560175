import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getSale, getNext, createSale, handleSearch } from "./thunk";

const initialState = {
  loading: false,
  loadingUpdated: false,
  loadingSale: false,
  loadingNext: false,
  count: null,
  next: null,
  previous: null,
  sales: [],
  edit: false,
  sale: null,
};

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    saleEditSuccess: (state, action) => {
      state.edit = true;
      state.sale = state.sales.find((item) => item.id === action.payload);
    },
    clearAllSale: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingSale = false;
      state.sale = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.sales = [...state.sales, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
      state.edit = false;
    });
    builder.addCase(createSale.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSale.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createSale.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });

    builder.addMatcher(isAnyOf(getSale.pending, handleSearch.pending), (state) => {
      state.loadingSale = true;
    });
    builder.addMatcher(isAnyOf(getSale.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingSale = false;
      state.sales = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getSale.rejected, handleSearch.rejected), (state) => {
      state.loadingSale = false;
    });
  },
});

export const { saleEditSuccess, clearAllSale } = saleSlice.actions;

export default saleSlice.reducer;
