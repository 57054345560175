import axiosInstance from "../../../Utils/axios";

// get paginated data
export const getSale = ({ postsPerPage, startDate = "", endDate = "", paymentMethod = "" }) =>
  axiosInstance.get(
    `api/v1/order/sell?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&payment_method=${paymentMethod}`
  );

//obtaining all data
export const getAllSale = () => axiosInstance.get(`api/v1/order/sell?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getSaleByPage = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/order/sell?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//creating function
export const createSale = (body) => axiosInstance.post(`api/v1/order/sell`, body);

//searching function
export const searchSale = ({ postsPerPage, startDate = "", endDate = "", paymentMethod = "", search }) =>
  axiosInstance.get(
    `api/v1/order/sell?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&payment_method=${paymentMethod}`
  );
