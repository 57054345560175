import React, { lazy, Suspense, useEffect } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import PageNotFound from "../Components/PageNotFound";
import ProtectedRoute from "./ProtectedRoute";
import { useSelector } from "react-redux";

//for refreshing the page when lazy fails loading the component
const lazyWithReload = (componentImport) =>
  lazy(async () => {
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem("page-has-been-force-refreshed") || "false"
    );
    try {
      const component = await componentImport();
      window.localStorage.setItem("page-has-been-force-refreshed", "false");
      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Assuming that the user is not on the latest version of the application.
        // Let's refresh the page immediately.
        window.localStorage.setItem("page-has-been-force-refreshed", "true");
        return window.location.reload();
      }
      // The page has already been reloaded
      // Assuming that user is already using the latest version of the application.
      throw error;
    }
  });

const OrganizationSetupAlert = lazyWithReload(() => import("../Components/Alert/OrganizationSetupAlert"));

// core app
const FiscalSessionAD = lazyWithReload(() => import("../Pages/FiscalSessionAD/Page"));
const FiscalSessionBS = lazyWithReload(() => import("../Pages/FiscalSessionBS/Page"));
const Country = lazyWithReload(() => import("../Pages/Country/Page"));
const Organization = lazyWithReload(() => import("../Pages/Organization/Page"));
const Dashboard = lazyWithReload(() => import("../Pages/Dashboard/Pages"));
const CoreSetupTabs = lazy(() => import("../Components/Tab/CoreSetupTab"));

// user setup
const UserListing = lazyWithReload(() => import("../Pages/User/Page"));
const UserGroupListing = lazyWithReload(() => import("../Pages/UserGroup/Page"));
const ChangePassword = lazyWithReload(() => import("../Pages/User/ResetPassword/ChangePassword"));

// item setup
const ItemListing = lazyWithReload(() => import("../Pages/Item/Pages"));
const MAterialTypeListing = lazyWithReload(() => import("../Pages/MaterialType/Pages"));
const UnitListing = lazyWithReload(() => import("../Pages/Unit/Pages"));
const CarpetTypeListing = lazyWithReload(() => import("../Pages/CarpetType/Pages"));
const QualityListing = lazyWithReload(() => import("../Pages/Quality/Pages"));
const ItemStockLimitListing = lazyWithReload(() => import("../Pages/ItemStockLimit/Pages"));

// party pages
const CustomerListing = lazyWithReload(() => import("../Pages/Customer/Pages"));
const SupplierListing = lazyWithReload(() => import("../Pages/Supplier/Pages"));

// customer order management pages
const CustomerOrderListing = lazyWithReload(() => import("../Pages/CustomerOrder/Page"));
const SaleListing = lazyWithReload(() => import("../Pages/Sale/Page"));
// purchase management pages
const PurchaseOrderListing = lazyWithReload(() => import("../Pages/PurchaseOrder/Pages"));
const PurchaseListing = lazyWithReload(() => import("../Pages/Purchase/Pages"));
const PurchaseReturnListing = lazyWithReload(() => import("../Pages/PurchaseReturn/Pages"));

// stock management pages
const StockAdditionListing = lazyWithReload(() => import("../Pages/StockAddition/Pages"));
const StockSubtractionListing = lazyWithReload(() => import("../Pages/StockSubtraction/Pages"));
const StockAnalysisListing = lazyWithReload(() => import("../Pages/StockAnalysis/Pages"));
const ItemLedgerListing = lazyWithReload(() => import("../Pages/ItemLedger/Pages"));

// production pages
const DesignCompositionLisiting = lazyWithReload(() => import("../Pages/DesignComposition/Pages"));
const DyeingListing = lazyWithReload(() => import("../Pages/Dyeing/Page"));
const WeavingListing = lazyWithReload(() => import("../Pages/Weaving/Page"));
const DyeHouseListing = lazyWithReload(() => import("../Pages/DyeHouse/Pages"));

// reports pages
const WeavingReportListing = lazyWithReload(() => import("../Pages/Reports/WeavingReport/Pages"));
const DyeingReportListing = lazyWithReload(() => import("../Pages/Reports/DyeingReport/Pages"));
const CustomerOrderReportListing = lazyWithReload(() => import("../Pages/Reports/CustomerOrderReport/Pages"));
const DayBookListing = lazyWithReload(() => import("../Pages/Reports/DayBookReport/Pages"));
const PayableReportListing = lazyWithReload(() => import("../Pages/Reports/PayableReport/Pages"));
const ReceivableReportListing = lazyWithReload(() => import("../Pages/Reports/ReceivableReport/Pages"));

const PrivateRoute = () => {
  const { isSetupDone } = useSelector((state) => state.auth);

  const ErrorFallback = ({ error }) => {
    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
      </div>
    );
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={""}>
        {isSetupDone ? (
          <Switch>
            <ProtectedRoute exact path="/" component={Dashboard} permission={""} />
            <ProtectedRoute exact path="/change-password" component={ChangePassword} permission={""} />

            {/* system setup */}
            {/* <ProtectedRoute path="/fiscalSessionBS" component={FiscalSessionBS} permission={""} />
            <ProtectedRoute path="/fiscalSessionAD" component={FiscalSessionAD} permission={""} /> */}

            {/* <ProtectedRoute path="/country" component={Country} permission={""} /> */}
            <ProtectedRoute path="/organization-setup" component={Organization} permission={""} />
            {/* user setup */}
            <ProtectedRoute exact path="/user" component={UserListing} permission={""} />
            <ProtectedRoute exact path="/user-group" component={UserGroupListing} permission={""} />

            {/* item Management */}
            <ProtectedRoute exact path="/item" component={ItemListing} permission={""} />
            <ProtectedRoute exact path="/material-type" component={MAterialTypeListing} permission={""} />
            <ProtectedRoute exact path="/carpet-type" component={CarpetTypeListing} permission={""} />
            <ProtectedRoute exact path="/unit" component={UnitListing} permission={""} />
            <ProtectedRoute exact path="/quality" component={QualityListing} permission={""} />
            <ProtectedRoute exact path="/item-stock-limit" component={ItemStockLimitListing} permission={""} />

            {/* party management */}
            <ProtectedRoute exact path="/customer" component={CustomerListing} permission={""} />
            <ProtectedRoute exact path="/supplier" component={SupplierListing} permission={""} />

            {/* customer order management */}
            <ProtectedRoute exact path="/order" component={CustomerOrderListing} permission={""} />
            <ProtectedRoute exact path="/sale" component={SaleListing} permission={""} />
            {/* purchase management */}
            <ProtectedRoute exact path="/purchase-order" component={PurchaseOrderListing} permission={""} />
            <ProtectedRoute exact path="/purchase" component={PurchaseListing} permission={""} />
            <ProtectedRoute exact path="/purchase-return" component={PurchaseReturnListing} permission={""} />

            {/* stock management */}
            <ProtectedRoute exact path="/stock-addition" component={StockAdditionListing} permission={""} />
            <ProtectedRoute exact path="/stock-subtraction" component={StockSubtractionListing} permission={""} />
            <ProtectedRoute exact path="/stock-analysis" component={StockAnalysisListing} permission={""} />
            <ProtectedRoute exact path="/item-ledger" component={ItemLedgerListing} permission={""} />

            {/* Production management */}
            <ProtectedRoute exact path="/design-composition" component={DesignCompositionLisiting} permission={""} />
            <ProtectedRoute exact path="/dyeing" component={DyeingListing} permission={""} />
            <ProtectedRoute exact path="/weaving" component={WeavingListing} permission={""} />
            <ProtectedRoute exact path="/dye-house" component={DyeHouseListing} permission={""} />

            {/* Reports */}
            <ProtectedRoute exact path="/weave-report" component={WeavingReportListing} permission={""} />
            <ProtectedRoute exact path="/dye-report" component={DyeingReportListing} permission={""} />
            <ProtectedRoute
              exact
              path="/customer-order-report"
              component={CustomerOrderReportListing}
              permission={""}
            />
            <ProtectedRoute exact path="/day-book" component={DayBookListing} permission={""} />
            <ProtectedRoute exact path="/payable-report" component={PayableReportListing} permission={""} />
            <ProtectedRoute exact path="/receivable-report" component={ReceivableReportListing} permission={""} />

            <Route path="*" component={PageNotFound} />
          </Switch>
        ) : (
          // <OrganizationSetupAlert />
          <Switch>
            <ProtectedRoute path="/organization-setup" component={Organization} permission={""} />
            <Route path="*" component={OrganizationSetupAlert} />
          </Switch>
        )}
      </Suspense>
    </ErrorBoundary>
  );
};

export default withRouter(PrivateRoute);
