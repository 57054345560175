import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get item
export const getShortcutItem = createAsyncThunk("shortcutItem/getShortcutItem", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getShortcutItem();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get next
export const getNext = createAsyncThunk("shortcutItem/getShortcutItem", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("shortcutItem/getShortcutItem", async (data, { rejectWithValue }) => {
  const { search } = data;
  try {
    const { data } = await API.handleSearch(search);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
