import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get DesignComposition
export const getDesignComposition = createAsyncThunk(
  "designComposition/getDesignComposition",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getDesignComposition(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all DesignComposition
export const getAllDesignComposition = createAsyncThunk(
  "designComposition/getAllDesignComposition",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllDesignComposition();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("designComposition/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("designComposition/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDesignComposition = createAsyncThunk(
  "designComposition/getPageDesignComposition",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDesignComposition(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create DesignComposition
export const createDesignComposition = createAsyncThunk(
  "designComposition/createDesignComposition",
  async (data, { rejectWithValue }) => {
    const { name, designDetails, description } = data;
    try {
      const body = JSON.stringify({
        name,
        designDetails,
        description,
      });
      const { data } = await API.createDesignComposition(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateDesignComposition = createAsyncThunk(
  "designComposition/updateDesignComposition",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { name, designDetails, description } = values;
    try {
      const body = JSON.stringify({
        name,
        designDetails,
        description,
      });
      const { data } = await API.updateDesignComposition(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("designComposition/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const getDesignCompositionById = createAsyncThunk(
  "designComposition/getDesignCompositionById",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getDesignCompositionById(id);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
