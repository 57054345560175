import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get item
export const getSale = createAsyncThunk(
  "sale/getSale",
  async ({ postsPerPage, startDate, endDate, paymentMethod }, { rejectWithValue }) => {
    try {
      const { data } = await API.getSale({ postsPerPage, startDate, endDate, paymentMethod });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const getAllSale = createAsyncThunk("sale/getAllSale", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllSale(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get previous
export const getPrevious = createAsyncThunk("sale/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("sale/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getParticularSale = createAsyncThunk("sale/getParticularSale", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getSaleByPage(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create Sale
export const createSale = createAsyncThunk("sale/createSale", async (data, { rejectWithValue }) => {
  const { paymentMethod, price, weavedRecievedOrder, notes } = data;
  try {
    const body = JSON.stringify({
      paymentMethod,
      price,
      weavedRecievedOrder,
      notes,
    });
    const { data } = await API.createSale(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk(
  "sale/handleSearch",
  async ({ postsPerPage, startDate, endDate, paymentMethod, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.searchSale({ postsPerPage, startDate, endDate, paymentMethod, search });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
