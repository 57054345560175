import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getPurchase, getNext, createPurchase, updatePurchase, handleSearch } from "./thunk";

const initialState = {
  purchases: [],
  edit: false,
  purchase: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingPurchase: false,
  loadingNext: false,
  purchaseDetails: [],
};

export const purchaseSlice = createSlice({
  name: "purchase",
  initialState,
  reducers: {
    purchaseDetailsAdded: (state, action) => {
      state.purchaseDetails = action.payload;
    },
    purchaseDetailsUpdated: (state, action) => {
      state.purchaseDetails = action.payload;
    },
    purchaseDetailsDeleted: (state, action) => {
      state.purchaseDetails = state.purchaseDetails.filter((detail) => detail.unique !== action.payload);
    },
    purchaseEditSuccess: (state, action) => {
      state.edit = true;
      state.purchase = state.purchases.find((purchase) => purchase.id === action.payload);
    },
    clearAllPurchase: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingPurchase = false;
      state.purchase = null;
      state.purchaseDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.purchases = [...state.purchases, ...action.payload.results];
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });

    builder.addCase(createPurchase.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPurchase.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createPurchase.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updatePurchase.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updatePurchase.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updatePurchase.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getPurchase.pending, handleSearch.pending), (state) => {
      state.loadingPurchase = true;
    });
    builder.addMatcher(isAnyOf(getPurchase.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingPurchase = false;
      state.edit = false;
      state.purchases = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getPurchase.rejected, handleSearch.rejected), (state) => {
      state.loadingPurchase = false;
      state.edit = false;
    });
  },
});

export const {
  purchaseDetailsAdded,
  purchaseDetailsDeleted,
  purchaseDetailsUpdated,
  purchaseEditSuccess,
  clearAllPurchase,
} = purchaseSlice.actions;

export default purchaseSlice.reducer;
