import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getQuality,
  getAllQuality,
  getNext,
  getPrevious,
  getPageQuality,
  createQuality,
  updateQuality,
  handleSearch,
} from "./thunk";

const initialState = {
  qualities: [],
  edit: false,
  quality: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingQuality: false,
  loadingNext: false,
};

export const qualitySlice = createSlice({
  name: "quality",
  initialState,
  reducers: {
    qualityEditSuccess: (state, action) => {
      state.edit = true;
      state.quality = state.qualities.find((quality) => quality.id === action.payload);
    },
    clearAllQuality: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingQuality = false;
      state.quality = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.qualities = [...state.qualities, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createQuality.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createQuality.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createQuality.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateQuality.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateQuality.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateQuality.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getQuality.pending, handleSearch.pending), (state) => {
      state.loadingQuality = true;
    });
    builder.addMatcher(isAnyOf(getQuality.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingQuality = false;
      state.qualities = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getQuality.rejected, handleSearch.rejected), (state) => {
      state.loadingQuality = false;
    });
  },
});

export const { qualityEditSuccess, clearAllQuality } = qualitySlice.actions;

export default qualitySlice.reducer;
