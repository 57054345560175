import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get stockSubtraction
export const getStockSubtraction = createAsyncThunk(
  "stockSubtraction/getStockSubtraction",
  async ({ postsPerPage, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.getStockSubtraction({ postsPerPage, startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all stockSubtraction
export const getAllStockSubtraction = createAsyncThunk(
  "stockSubtraction/getAllStockSubtraction",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllStockSubtraction();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("stockSubtraction/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("stockSubtraction/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageStockSubtraction = createAsyncThunk(
  "stockSubtraction/getPageStockSubtraction",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageStockSubtraction(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create stockAddition
export const createStockSubtraction = createAsyncThunk(
  "stockSubtration/createStockSubtraction",
  async (updated, { rejectWithValue, dispatch }) => {
    const { item, quantity, isAddition, reason, colorCode, type } = updated;
    try {
      const body = JSON.stringify({ item, quantity, isAddition, reason, colorCode, type });
      const { data } = await API.createStockSubtraction(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// handle search
export const handleSearch = createAsyncThunk(
  "stockSubtraction/handleSearch",
  async ({ postsPerPage, startDate, endDate, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ search, postsPerPage, startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
