import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get materialType
export const getMaterialType = createAsyncThunk("materialType/getMaterialType", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getMaterialType(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all materialType
export const getAllMaterialType = createAsyncThunk("materialType/getAllMaterialType", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllMaterialType();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("materialType/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("materialType/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageMaterialType = createAsyncThunk("materialType/getPageMaterialType", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageMaterialType(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create materialType
export const createMaterialType = createAsyncThunk("materialType/createMaterialType", async (data, { rejectWithValue }) => {
  const { name, description } = data;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.createMaterialType(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateMaterialType = createAsyncThunk("materialType/updateMaterialType", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, description } = values;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.updateMaterialType(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("materialType/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
