import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getPurchaseOrder,
  getNext,
  getSpecificPurchaseOrder,
  createPurchaseOrder,
  updatePurchaseOrder,
  createPurchaseOrderReceive,
  getPurchaseOrderReceivedDetail,
  purchaseOrderCancel,
  handleSearch,
} from "./thunk";

const initialState = {
  purchaseOrders: [],
  edit: false,
  purchaseOrder: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingSpecific: false,
  loadingPurchaseOrder: false,
  loadingNext: false,
  loadingPurchaseOrderReceived: false,
  loadingPurchaseOrderCancel: false,
  purchaseOrderReceived: null,
  purchaseOrderDetails: [],
};

export const purchaseOrderSlice = createSlice({
  name: "purchaseOrder",
  initialState,
  reducers: {
    purchaseOrderDetialsAdded: (state, action) => {
      state.purchaseOrderDetails = action.payload;
    },
    purchaseOrderDetialsUpdated: (state, action) => {
      state.purchaseOrderDetails = action.payload;
    },
    purchaseOrderDetialsDeleted: (state, action) => {
      state.purchaseOrderDetails = state.purchaseOrderDetails?.filter((detail) => detail?.unique !== action.payload);
    },
    purchaseOrderEditSuccess: (state, action) => {
      state.edit = true;
    },
    clearAllPurchaseOrder: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingPurchaseOrder = false;
      state.loadingPurchaseOrderReceived = false;
      state.purchaseOrderReceived = null;
      state.purchaseOrder = null;
      state.purchaseOrderDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.purchaseOrders = [...state.purchaseOrders, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createPurchaseOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPurchaseOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createPurchaseOrder.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(purchaseOrderCancel.pending, (state) => {
      state.loadingPurchaseOrderCancel = true;
    });
    builder.addCase(purchaseOrderCancel.fulfilled, (state, action) => {
      state.loadingPurchaseOrderCancel = false;
    });
    builder.addCase(purchaseOrderCancel.rejected, (state) => {
      state.loadingPurchaseOrderCancel = false;
      state.edit = false;
    });
    builder.addCase(createPurchaseOrderReceive.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPurchaseOrderReceive.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createPurchaseOrderReceive.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updatePurchaseOrder.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updatePurchaseOrder.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updatePurchaseOrder.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addCase(getSpecificPurchaseOrder.pending, (state) => {
      state.loadingSpecific = true;
    });
    builder.addCase(getSpecificPurchaseOrder.fulfilled, (state, action) => {
      const updatedPurchaseOrderDetails = action.payload.purchaseOrderDetails.map((detail) => ({
        ...detail,
        receivedQuantity: 0,
        unique: uuidv4(),
      }));
      state.loadingSpecific = false;
      state.purchaseOrder = action.payload;
      state.purchaseOrderDetails = updatedPurchaseOrderDetails;
    });
    builder.addCase(getSpecificPurchaseOrder.rejected, (state) => {
      state.loadingSpecific = false;
    });
    builder.addCase(getPurchaseOrderReceivedDetail.pending, (state) => {
      state.loadingPurchaseOrderReceived = true;
    });
    builder.addCase(getPurchaseOrderReceivedDetail.fulfilled, (state, action) => {
      state.loadingPurchaseOrderReceived = false;
      state.purchaseOrderReceived = action.payload.results[0];
    });
    builder.addCase(getPurchaseOrderReceivedDetail.rejected, (state) => {
      state.loadingPurchaseOrderReceived = false;
    });
    builder.addMatcher(isAnyOf(getPurchaseOrder.pending, handleSearch.pending), (state) => {
      state.loadingPurchaseOrder = true;
    });
    builder.addMatcher(isAnyOf(getPurchaseOrder.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingPurchaseOrder = false;
      state.edit = false;
      state.purchaseOrders = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getPurchaseOrder.rejected, handleSearch.rejected), (state) => {
      state.loadingPurchaseOrder = false;
      state.edit = false;
    });
  },
});

export const {
  purchaseOrderDetialsAdded,
  purchaseOrderDetialsUpdated,
  purchaseOrderDetialsDeleted,
  purchaseOrderEditSuccess,
  clearAllPurchaseOrder,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
