import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get dyeingReport
export const getDyeingReport = createAsyncThunk(
  "dyeingReport/getDyeingReport",
  async ({ postsPerPage, startDate, endDate, customer, status, dyeingHouse }, { rejectWithValue }) => {
    try {
      const { data } = await API.getDyeingReport({ postsPerPage, startDate, endDate, customer, status, dyeingHouse });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all dyeingReport
export const getAllDyeingReport = createAsyncThunk(
  "dyeingReport/getAllDyeingReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllDyeingReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("dyeingReport/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("dyeingReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDyeingReport = createAsyncThunk(
  "dyeingReport/getPageDyeingReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDyeingReport(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "dyeingReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, dyeingHouse, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({
        postsPerPage,
        startDate,
        endDate,
        customer,
        status,
        dyeingHouse,
        search,
      });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
