import axiosInstance from "../../../Utils/axios";

// get numeric data for dashboard
export const getNumericData = () => axiosInstance.get(`api/v1/report/numeric-dashboard?offset=0&limit=0`);

// get numeric finance data for dashboard
export const getNumericFinanceData = () =>
  axiosInstance.get(`api/v1/report/numeric-finance-dashboard?offset=0&limit=0`);

// get purchase line chart data for dashboard
export const getPurchaseLineChartData = (filter) =>
  axiosInstance.get(`api/v1/report/purchase-line-chart?offset=0&limit=0&date_range=${filter}`);

// get purchase line chart data for dashboard
export const getSaleLineChartData = (filter) =>
  axiosInstance.get(`api/v1/report/sale-line-chart?offset=0&limit=0&date_range=${filter}`);

// get purchase line chart data for dashboard
export const getDyeingLineChartData = (filter) =>
  axiosInstance.get(`api/v1/report/dyeing-line-chart?offset=0&limit=0&date_range=${filter}`);

// get purchase line chart data for dashboard
export const getWeavingLineChartData = (filter) =>
  axiosInstance.get(`api/v1/report/weaving-line-chart?offset=0&limit=0&date_range=${filter}`);
