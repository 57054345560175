import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get customer
export const getCustomer = createAsyncThunk("customer/getCustomer", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getCustomer(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all customer
export const getAllCustomer = createAsyncThunk("customer/getAllCustomer", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllCustomer();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("customer/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("customer/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageCustomer = createAsyncThunk("customer/getPageCustomer", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageCustomer(number, postsPerPage);
    return data;
  } catch (error) {
   return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create customer
export const createCustomer = createAsyncThunk("customer/createCustomer", async (data, { rejectWithValue }) => {
  const { name, email, phoneNumber, address, city, state, country, postalCode } = data;
  try {
    const body = JSON.stringify({ name, email, phoneNumber, address, city, state, country, postalCode });
    const { data } = await API.createCustomer(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateCustomer = createAsyncThunk("customer/updateCustomer", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, email, phoneNumber, address, city, state, country, postalCode } = values;
  try {
    const body = JSON.stringify({ name, email, phoneNumber, address, city, state, country, postalCode });
    const { data } = await API.updateCustomer(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("customer/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
   return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
