import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get purchaseOrder
export const getPurchaseOrder = createAsyncThunk(
  "purchaseOrder/getPurchaseOrder",
  async ({ postsPerPage, startDate, endDate, supplier, status }, { rejectWithValue }) => {
    try {
      const { data } = await API.getPurchaseOrder({ postsPerPage, startDate, endDate, supplier, status });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all purchaseOrder
export const getAllPurchaseOrder = createAsyncThunk(
  "purchaseOrder/getAllPurchaseOrder",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllPurchaseOrder();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("purchaseOrder/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("purchaseOrder/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPagePurchaseOrder = createAsyncThunk(
  "purchaseOrder/getPagePurchaseOrder",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPagePurchaseOrder(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get specific order
export const getSpecificPurchaseOrder = createAsyncThunk(
  "purchaseOrder/getSpecificPurchaseOrder",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSpecificPurchaseOrder(id);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create purchaseOrder
export const createPurchaseOrder = createAsyncThunk(
  "purchaseOrder/createPurchaseOrder",
  async (data, { rejectWithValue }) => {
    const { supplier, remarks, purchaseOrderDetails } = data;
    try {
      const body = JSON.stringify({ supplier, remarks, purchaseOrderDetails });
      const { data } = await API.createPurchaseOrder(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updatePurchaseOrder = createAsyncThunk(
  "purchaseOrder/updatePurchaseOrder",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { supplier, remarks, purchaseOrderDetails } = values;
    try {
      const body = JSON.stringify({ supplier, remarks, purchaseOrderDetails });
      const { data } = await API.updatePurchaseOrder(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create purchaseOrderReceive
export const createPurchaseOrderReceive = createAsyncThunk(
  "purchaseOrder/createPurchaseOrderReceive",
  async (data, { rejectWithValue }) => {
    const {
      supplier,
      billNo,
      purchaseOrderMaster,
      remarks,
      goodsReceiptDetails,
      netTotalRecievedPrice,
      grossTotalRecievedPrice,
      discount,
      extraCharge,
    } = data;
    try {
      const body = JSON.stringify({
        supplier,
        billNo,
        purchaseOrderMaster,
        remarks,
        goodsReceiptDetails,
        netTotalRecievedPrice,
        grossTotalRecievedPrice,
        discount,
        extraCharge,
      });
      const { data } = await API.createPurchaseOrderReceive(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

//payment
export const goodsReceiptPayment = createAsyncThunk(
  "purchaseOrder/goodsReceiptPayment",
  async (data, { rejectWithValue }) => {
    const { goodReceiptMaster, paymentMethod, amount, remarks } = data;
    try {
      const body = JSON.stringify({
        goodReceiptMaster,
        paymentMethod,
        amount,
        remarks,
      });
      const { data } = await API.goodsReceiptPayment(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get received detail
export const getPurchaseOrderReceivedDetail = createAsyncThunk(
  "purchaseOrder/getPurchaseOrderReceivedDetail",
  async (purchaseOrderNo, { rejectWithValue }) => {
    try {
      const { data } = await API.getPurchaseOrderReceivedDetail(purchaseOrderNo);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// purchase order cancel
export const purchaseOrderCancel = createAsyncThunk(
  "purchaseOrder/purchaseOrderCancel",
  async (data, { rejectWithValue }) => {
    const { id, cancelReason } = data;
    try {
      const body = JSON.stringify({ status: "CANCELLED", cancelReason });
      const { data } = await API.purchaseOrderCancel(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("purchaseOrder/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage, startDate, endDate, supplier, status } = data;
  try {
    const { data } = await API.handleSearch({ search, postsPerPage, startDate, endDate, supplier, status });
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
