import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get stockAnalysis
export const getStockAnalysis = createAsyncThunk(
  "stockAnalysis/getStockAnalysis",
  async ({ postsPerPage, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.getStockAnalysis({ postsPerPage, startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all stockAnalysis
export const getAllStockAnalysis = createAsyncThunk(
  "stockAnalysis/getAllStockAnalysis",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllStockAnalysis();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("stockAnalysis/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("stockAnalysis/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageStockAnalysis = createAsyncThunk(
  "stockAnalysis/getPageStockAnalysis",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageStockAnalysis(number, postsPerPage);
      return data;
    } catch (error) {
      return   rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "stockAnalysis/handleSearch",
  async ({ postsPerPage, startDate, endDate, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, search });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
