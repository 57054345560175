import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import { getPurchaseReturn, getNext, createPurchaseReturn, getPurchaseDetails, handleSearch } from "./thunk";

const initialState = {
  purchaseReturns: [],
  edit: false,
  purchaseReturn: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingNext: false,
  loadingPurchaseReturn: false,
  loadingPurchaseDetails: false,
  purchaseDetails: [],
};

export const purchaseReturnSlice = createSlice({
  name: "purchaseReturn",
  initialState,
  reducers: {
    purchaseDetailsAdded: (state, action) => {
      state.purchaseDetails = action.payload;
    },
    purchaseDetailsUpdated: (state, action) => {
      state.purchaseDetails = action.payload;
    },
    purchaseDetailsDeleted: (state, action) => {
      state.purchaseDetails = state.purchaseDetails.filter((detail) => detail.unique !== action.payload);
    },
    purchaseReturnEditSuccess: (state, action) => {
      state.edit = true;
      state.purchaseReturn = state.purchaseReturns.find((purchaseReturn) => purchaseReturn.id === action.payload);
    },
    clearPurchaseDetails: (state) => {
      state.purchaseDetails = [];
    },
    clearAllPurchaseReturn: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingPurchaseDetails = false;
      state.purchaseReturn = null;
      state.purchaseDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.purchaseReturns = [...state.purchaseReturns, ...action.payload.results];
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createPurchaseReturn.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createPurchaseReturn.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createPurchaseReturn.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });

    builder.addCase(getPurchaseDetails.pending, (state) => {
      state.loadingPurchaseDetails = true;
    });
    builder.addCase(getPurchaseDetails.fulfilled, (state, action) => {
      const updatedPurchaseDetails = action.payload?.results?.map((detail) => ({
        ...detail,
        unique: uuidv4(),
        returnQuantity: 0,
      }));
      state.purchaseDetails = updatedPurchaseDetails;
      state.loadingPurchaseDetails = false;
    });
    builder.addCase(getPurchaseDetails.rejected, (state) => {
      state.loadingPurchaseDetails = false;
    });
    builder.addMatcher(isAnyOf(getPurchaseReturn.pending, handleSearch.pending), (state) => {
      state.loadingPurchaseReturn = true;
    });
    builder.addMatcher(isAnyOf(getPurchaseReturn.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingPurchaseReturn = false;
      state.edit = false;
      state.purchaseReturns = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getPurchaseReturn.rejected, handleSearch.rejected), (state) => {
      state.loadingPurchaseReturn = false;
      state.edit = false;
    });
  },
});

export const {
  purchaseDetailsAdded,
  purchaseDetailsDeleted,
  purchaseDetailsUpdated,
  purchaseReturnEditSuccess,
  clearPurchaseDetails,
  clearAllPurchaseReturn,
} = purchaseReturnSlice.actions;

export default purchaseReturnSlice.reducer;
