import { TbMapSearch } from "react-icons/tb";
import { BiCollection } from "react-icons/bi";
import { BsPersonVcard } from "react-icons/bs";
import { TbReportMoney } from "react-icons/tb";
import { TiLocationArrowOutline } from "react-icons/ti";
import { RiSettings4Line } from "react-icons/ri";
import { RxDashboard } from "react-icons/rx";
import { BiPurchaseTag } from "react-icons/bi";
import { BiSolidReport } from "react-icons/bi";
import { FaUser } from "react-icons/fa";

import { MdOutlineInventory } from "react-icons/md";

export const sidebarData = [
  {
    menu: "Purchase Management",
    icon: <BiPurchaseTag size={16} />,
    key: "purchase",
    permissions: ["can_read_purchase_order_master", "can_read_purchase_master", "can_read_purchase_return"],
    sub_menu: [
      {
        name: "Purchase Order",
        link: "/purchase-order",

        permissions: [
          "can_read_purchase_order_master",
          "can_create_purchase_order_master",
          "can_update_purchase_order_master",
        ],
      },
      {
        name: "Purchase",
        link: "/purchase",
        permissions: ["can_read_purchase_master", "can_create_purchase_master", "can_update_purchase_master"],
      },
      {
        name: "Purchase Return",
        link: "/purchase-return",
        permissions: ["can_read_purchase_return", "can_create_purchase_return", "can_update_purchase_return"],
      },
    ],
  },
  {
    menu: "Customer Order",
    icon: <RxDashboard size={16} />,
    key: "customer",
    permissions: ["can_read_customer_order", "can_read_sale", "can_read_carpet_type"],
    sub_menu: [
      {
        name: "Order",
        link: "/order",
        permissions: ["can_read_customer_order", "can_create_customer_order", "can_update_customer_order"],
      },
      {
        name: "Sale",
        link: "/sale",
        permissions: ["can_read_sale", "can_create_sale", "can_update_sale"],
      },
      {
        name: "Carpet Type",
        link: "/carpet-type",
        permissions: ["can_read_carpet_type", "can_create_carpet_type", "can_update_carpet_type", "delete_carpet_type"],
      },
    ],
  },

  {
    menu: "Production Management",
    icon: <TiLocationArrowOutline size={16} />,
    key: "production",
    permissions: ["can_read_dyeing_master", "can_read_weaving", "can_read_dyeing_house", "can_read_design_master"],
    sub_menu: [
      {
        name: "Design Composition",
        link: "/design-composition",
        permissions: ["can_read_design_master", "can_update_design_master", "can_create_design_master"],
      },
      {
        name: "Dyeing",
        link: "/dyeing",
        permissions: ["can_read_dyeing_master", "can_update_dyeing_master", "can_create_dyeing_master"],
      },
      {
        name: "Weaving",
        link: "/weaving",
        permissions: ["can_read_weaving", "can_update_weaving", "can_create_weaving"],
      },
      {
        name: "Dye House",
        link: "/dye-house",
        permissions: ["can_read_dyeing_house", "can_update_dyeing_house", "can_create_dyeing_house"],
      },
    ],
  },
  {
    menu: "Inventory",
    icon: <MdOutlineInventory size={16} />,
    key: "inventory",
    permissions: ["can_read_stock"],
    sub_menu: [
      {
        name: "Stock Analysis",
        link: "/stock-analysis",
        permissions: ["can_read_stock"],
      },
      {
        name: "Item Ledger",
        link: "/item-ledger",
        permissions: ["can_read_stock"],
      },
      {
        name: "Stock Addition",
        link: "/stock-addition",
        permissions: ["can_read_stock", "can_create_stock", "can_update_stock"],
      },
      {
        name: "Stock Subtraction",
        link: "/stock-subtraction",
        permissions: ["can_read_stock", "can_create_stock", "can_update_stock"],
      },
    ],
  },
  {
    menu: "Item Setup",
    icon: <BiCollection size={16} />,
    key: "item",
    permissions: ["can_read_item"],
    sub_menu: [
      {
        name: "Item",
        link: "/item",
        permissions: ["can_read_item", "can_create_item", "can_update_item"],
      },

      {
        name: "Material Type",
        link: "/material-type",
        permissions: ["can_read_item", "can_create_item", "can_update_item"],
      },
      {
        name: "Quality",
        link: "/quality",
        permissions: ["can_read_item", "can_create_item", "can_update_item"],
      },
      {
        name: "Item Stock Limit",
        link: "/item-stock-limit",
        permissions: ["can_read_item", "can_create_item", "can_update_item"],
      },
    ],
  },
  {
    menu: "Party Management",
    icon: <FaUser size={16} />,
    key: "party",
    permissions: ["can_read_customer", "can_read_supplier"],
    sub_menu: [
      {
        name: "Customer",
        link: "/customer",
        permissions: ["can_read_customer", "can_create_customer", "can_update_customer"],
      },
      {
        name: "Supplier",
        link: "/supplier",
        permissions: ["can_read_supplier", "can_create_supplier", "can_update_supplier"],
      },
    ],
  },
  {
    menu: "Report",
    icon: <BiSolidReport size={16} />,
    key: "report",
    permissions: ["can_read_reports"],
    sub_menu: [
      {
        name: "Day Book",
        link: "/day-book",
        permissions: ["can_read_reports"],
      },
      {
        name: "Customer Order Report",
        link: "/customer-order-report",
        permissions: ["can_read_reports"],
      },
      {
        name: "Dye Report",
        link: "/dye-report",
        permissions: ["can_read_reports"],
      },
      {
        name: "Weave Report",
        link: "/weave-report",
        permissions: ["can_read_reports"],
      },
      {
        name: "Payable Report",
        link: "/payable-report",
        permissions: ["can_read_reports"],
      },
      {
        name: "Receivable Report",
        link: "/receivable-report",
        permissions: ["can_read_reports"],
      },
    ],
  },
  // {
  //   menu: "Finance",
  //   icon: <BsPersonVcard size={16} />,
  //   link: "/finance",
  //   permissions: ["can_read_reports"],
  // },
];

export const admin = [
  {
    menu: "Setting",
    icon: <RiSettings4Line size={18} />,
    permissions: ["can_read_organization", "can_read_user", "can_read_user_group"],
    sub_menu: [
      {
        name: "System Setup",
        link: "/system-setup",
        key: "coreSetup",
        permissions: ["can_read_organization"],
        child_menu: [
          {
            name: "Organization",
            link: "/organization-setup",
            permissions: ["can_read_organization", "can_create_organization", "can_update_organization"],
          },
        ],
      },
      {
        name: "User Setup",
        link: "/user-setup",
        key: "userSetup",
        permissions: ["can_read_user", "can_read_customgroup"],
        child_menu: [
          {
            name: "User",
            link: "/user",
            permissions: ["can_update_user", "can_read_user", "can_create_user"],
          },
          {
            name: "User Group",
            link: "/user-group",
            permissions: ["can_create_customgroup", "can_read_customgroup", "can_update_customgroup"],
          },
        ],
      },
    ],
  },
];
