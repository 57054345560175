import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get weaving
export const getWeaving = createAsyncThunk(
  "weaving/getWeaving",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier, isPaid }, { rejectWithValue }) => {
    try {
      const { data } = await API.getWeaving({ postsPerPage, startDate, endDate, customer, status, supplier, isPaid });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const getAllWeaving = createAsyncThunk("weaving/getAllWeaving", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllWeaving(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get previous
export const getPrevious = createAsyncThunk("weaving/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("weaving/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageWeaving = createAsyncThunk("weaving/getPageWeaving", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageWeaving(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

export const createWeaving = createAsyncThunk("weaving/createWeaving", async (data, { rejectWithValue }) => {
  const { customerOrder, supplier, weavingDetails, saveAsDraft } = data;
  try {
    const body = JSON.stringify({
      customerOrder,
      supplier,
      weavingDetails,
      saveAsDraft,
    });
    const { data } = await API.createWeaving(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

export const updateWeaving = createAsyncThunk("weaving/updateWeaving", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { customerOrder, supplier, weavingDetails, saveAsDraft, remarks } = values;
  try {
    const body = JSON.stringify({
      customerOrder,
      supplier,
      weavingDetails,
      saveAsDraft,
      remarks,
    });
    const { data } = await API.updateWeaving(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get weaving details
export const getWeavingDetails = createAsyncThunk("weaving/getWeavingDetails", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getWeavingDetails(id);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// specific weaving
export const getSpecificWeaving = createAsyncThunk("weaving/getSpecificWeaving", async (id, { rejectWithValue }) => {
  try {
    const { data } = await API.getSpecificWeaving(id);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const getSepecificWeavingReceive = createAsyncThunk(
  "weaving/getSepecificWeavingReceive",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await API.getSepecificWeavingReceive(id);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// save weave as draft weave receive
export const createWeaveReceive = createAsyncThunk(
  "weaving/createWeaveReceive",
  async (createData, { rejectWithValue }) => {
    try {
      let cleanedObj = Object.fromEntries(Object.entries(createData).filter(([key, value]) => value !== ""));
      const body = JSON.stringify({ ...cleanedObj });
      const { data } = await API.createWeaveReceive(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateWeaveReceive = createAsyncThunk(
  "weaving/updateWeaveReceive",
  async (updateData, { rejectWithValue }) => {
    try {
      const { id, createData } = updateData;
      let cleanedObj = Object.fromEntries(Object.entries(createData).filter(([key, value]) => value !== ""));
      const body = JSON.stringify({ ...cleanedObj });
      const { data } = await API.updateWeaveReceive(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

//provide extra data
export const issueExtraYarn = createAsyncThunk("weaving/issueExtraYarn", async (updateData, { rejectWithValue }) => {
  try {
    const { id, createData } = updateData;

    let cleanedObj = Object.fromEntries(Object.entries(createData).filter(([key, value]) => value !== ""));
   
    const body = JSON.stringify({ ...cleanedObj });
    const { data } = await API.issueExtraYarn(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// save weave as draft
export const saveAsDraft = createAsyncThunk("weaving/saveAsDraft", async (dataCreate, { rejectWithValue }) => {
  try {
    const { id, createData } = dataCreate;
    let cleanedObj = Object.fromEntries(Object.entries(createData).filter(([key, value]) => value !== ""));
    const body = JSON.stringify({ ...cleanedObj });
    const { data } = await API.saveAsDraft(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// save weave as draft
export const saveWeaveDetails = createAsyncThunk(
  "weaving/saveWeaveDetails",
  async (createData, { rejectWithValue }) => {
    try {
      const body = JSON.stringify({ ...createData });
      const { data } = await API.saveWeaveDetails(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const weavingCancel = createAsyncThunk("weaving/weavingCancel", async (data, { rejectWithValue }) => {
  const { id, reason } = data;
  try {
    const body = JSON.stringify({ status: "CANCELLED", reason });
    const { data } = await API.weavingCancel(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk(
  "weaving/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier, search, isPaid }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({
        postsPerPage,
        startDate,
        endDate,
        customer,
        status,
        supplier,
        search,
        isPaid,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

export const makePayment = createAsyncThunk("weaving/makePayment", async (values, { rejectWithValue }) => {
  const {
    paymentType,
    weavingReceivedMaster,
    amount,
    dateOfPayment,
    unit,
    finishedLength,
    finishedBreadth,
    rate,
    penalty,
    extraCharge,
    totalGrossWeavingCost,
    totalWeavingCost,
    remarks,
  } = values;

  try {
    const body = JSON.stringify({
      paymentType,
      weavingReceivedMaster,
      amount,
      dateOfPayment,
      unit,
      finishedLength,
      finishedBreadth,
      rate,
      penalty,
      extraCharge,
      totalGrossWeavingCost,
      totalWeavingCost,
      remarks,
    });
    const { data } = await API.makePayment(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
