import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {
  getWeaving,
  getNext,
  createWeaving,
  updateWeaving,
  getSpecificWeaving,
  getSepecificWeavingReceive,
  weavingCancel,
  handleSearch,
  getWeavingDetails,
} from "./thunk";
import { unique } from "jquery";

const initialState = {
  loading: false,
  loadingUpdated: false,
  loadingWeaving: false,
  loadingNext: false,
  count: null,
  next: null,
  previous: null,
  weavings: [],
  edit: false,
  weaving: null,
  loadingSpecific: false,
  loadingCancel: false,
  loadingDetails: false,
  weavingDetails: [],
  // for receive
  loadingReceive: false,
  weavingReceived: null,
  // for draft & receive

  weavingDraft: null,
  saveAsDraft: false,

  // for received weave details
  loadingReceivedDetails: false,
  receivedWeave: null,
  weavingReceive: null,
  weavingReceivedDetails: [],
};

export const weavingSlice = createSlice({
  name: "weaving",
  initialState,
  reducers: {
    weavingRawMaterialAdded: (state, action) => {
      state.weavingDetails = action.payload;
    },
    weavingRawMaterialUpdated: (state, action) => {
      state.weavingDetails = action.payload;
    },
    weavingRawMaterialDeleted: (state, action) => {
      state.weavingDetails = state.weavingDetails?.filter((temp) => temp.unique !== action.payload);
    },
    // for weaving receive
    getWeavingReceiveDetails: (state, action) => {
      state.weavingReceivedDetails = action.payload.map((value) => {
        return { ...value, unique: uuidv4() };
      });
    },
    weavingReceiveDetailsAdded: (state, action) => {
      state.weavingReceivedDetails = action.payload;
    },
    weavingReceiveDetailsUpdated: (state, action) => {
      state.weavingReceivedDetails = action.payload;
    },
    weavingReceiveDetailsDeleted: (state, action) => {
      state.weavingReceivedDetails = state.weavingReceivedDetails.filter((temp) => temp.unique !== action.payload);
    },
    weavingEditSuccess: (state) => {
      state.edit = true;
    },
    clearWeavingDetails: (state) => {
      state.loadingDetails = false;
      state.weavingDetails = [];
    },

    clearAllWeaving: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingWeaving = false;
      state.weaving = null;
      state.weavingDetails = [];
      state.weavingReceivedDetails = [];
      state.weavingDraft = null;
      state.saveAsDraft = false;
      state.loadingReceivedDetails = false;
      state.receivedWeave = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.weavings = [...state.weavings, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
      state.edit = false;
    });
    builder.addCase(createWeaving.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createWeaving.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createWeaving.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateWeaving.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateWeaving.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateWeaving.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addCase(weavingCancel.pending, (state) => {
      state.loadingCancel = true;
    });
    builder.addCase(weavingCancel.fulfilled, (state, action) => {
      state.loadingCancel = false;
    });
    builder.addCase(weavingCancel.rejected, (state) => {
      state.loadingCancel = false;
      state.edit = false;
    });
    builder.addCase(getWeavingDetails.pending, (state) => {
      state.loadingDetails = true;
    });
    builder.addCase(getWeavingDetails.fulfilled, (state, action) => {
      const updatedWeavingDetails = action.payload.results?.map((detail) => {
        if (detail?.type === "NON_NATURAL") {
          return {
            item: detail?.item,
            colorCode: detail?.colorCode,
            type: detail?.type,
            quantity: Number(detail?.quantityFromDyeing),
            remarks: "From Dyeing",
            unique: uuidv4(),
          };
        } else {
          return {
            item: detail?.item,
            colorCode: detail?.colorCode,
            type: detail?.type,
            quantity: Number(detail?.quantityFromCustomerOrder),
            remarks: "From Stock",
            unique: uuidv4(),
          };
        }
      });

      state.loadingDetails = false;
      state.weavingDetails = updatedWeavingDetails;
    });
    builder.addCase(getWeavingDetails.rejected, (state) => {
      state.loadingDetails = false;
      state.edit = false;
    });
    builder.addCase(getSpecificWeaving.pending, (state) => {
      state.loadingSpecific = true;
    });
    builder.addCase(getSpecificWeaving.fulfilled, (state, action) => {
      state.loadingSpecific = false;
      state.weaving = action.payload;
      state.weavingDetails = action.payload.weavingDetails.map((value) => {
        return { ...value, unique: uuidv4() };
      });
    });
    builder.addCase(getSpecificWeaving.rejected, (state) => {
      state.loadingSpecific = false;
    });

    // specific weaving received
    builder.addCase(getSepecificWeavingReceive.pending, (state) => {
      state.loadingSpecific = true;
    });
    builder.addCase(getSepecificWeavingReceive.fulfilled, (state, action) => {
      const receivedData = action.payload.results[0];

      state.loadingSpecific = false;
      state.weavingReceive = receivedData ? receivedData : null;
      state.weavingReceivedDetails = receivedData?.weavingReceivedDetails
        ? receivedData?.weavingReceivedDetails.map((value) => {
            return { ...value, unique: uuidv4() };
          })
        : [];
    });
    builder.addCase(getSepecificWeavingReceive.rejected, (state) => {
      state.loadingSpecific = false;
    });

    builder.addMatcher(isAnyOf(getWeaving.pending, handleSearch.pending), (state) => {
      state.loadingWeaving = true;
    });
    builder.addMatcher(isAnyOf(getWeaving.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingWeaving = false;
      state.weavings = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getWeaving.rejected, handleSearch.rejected), (state) => {
      state.loadingWeaving = false;
      state.edit = false;
    });
  },
});

export const {
  weavingRawMaterialAdded,
  weavingRawMaterialUpdated,
  weavingRawMaterialDeleted,
  // for receive weaving
  getWeavingReceiveDetails,
  weavingReceiveDetailsAdded,
  weavingReceiveDetailsUpdated,
  weavingReceiveDetailsDeleted,
  clearWeavingDetails,
  weavingEditSuccess,
  clearAllWeaving,
} = weavingSlice.actions;

export default weavingSlice.reducer;
