import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get payableReport
export const getPayableReport = createAsyncThunk(
  "payableReport/getPayableReport",
  async ({ postsPerPage, startDate, endDate, customer, dyeingHouse, supplier }, { rejectWithValue }) => {
    try {
      const { data } = await API.getPayableReport({
        postsPerPage,
        startDate,
        endDate,
        customer,
        dyeingHouse,
        supplier,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all payableReport
export const getAllPayableReport = createAsyncThunk(
  "payableReport/getAllPayableReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllPayableReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("payableReport/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("payableReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPagePayableReport = createAsyncThunk(
  "payableReport/getPagePayableReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPagePayableReport(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "payableReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, dyeingHouse, supplier, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({
        search,
        postsPerPage,
        startDate,
        endDate,
        customer,
        dyeingHouse,
        supplier,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
