import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getDayBookReport, getNext, handleSearch } from "./thunk";

const initialState = {
  dayBooks: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingDayBook: false,
};

export const dayBookReportSlice = createSlice({
  name: "dayBookReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.dayBooks = [...state.dayBooks, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getDayBookReport.pending, handleSearch.pending), (state) => {
      state.loadingDayBook = true;
    });
    builder.addMatcher(isAnyOf(getDayBookReport.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingDayBook = false;
      state.dayBooks = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getDayBookReport.rejected, handleSearch.rejected), (state) => {
      state.loadingDayBook = false;
    });
  },
});

export const {} = dayBookReportSlice.actions;

export default dayBookReportSlice.reducer;
