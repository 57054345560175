import React, { Suspense, lazy, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./layout.css";
import SidebarData from "../Sidebar/Sidebar";
import autoAnimate from "@formkit/auto-animate";
import { clearItems } from "../../Redux/ShortcutRedux/shortcutItemSlice";

const Modal = lazy(() => import("../Modal/Modal"));
const ItemListing = lazy(() => import("../ShortcutModals/ItemListing"));

const Layout = ({ children }) => {
  const dispatch = useDispatch();

  const [openItemListModal, setOpenItemListModal] = useState(false);
  const handleKeyDown = (event) => {
    if (event.key === "Escape" && openItemListModal) {
      dispatch(clearItems());
      setOpenItemListModal(false);
    } else if (event.ctrlKey && event.key === "i") {
      event.preventDefault();
      setOpenItemListModal(true);
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const collapsed = useSelector((state) => state.layout.collapsed);

  return (
    <>
      <div id="layout-wrapper" className="d-flex">
        <SidebarData />
        <div className={`${collapsed ? "main-content collapsed" : "main-content"}`}>
          <div className="page-content">
            <div className="container-fluid p-0">{children}</div>
          </div>
        </div>
      </div>
      {openItemListModal && (
        <Suspense fallback={<div></div>}>
          <Modal
            showModal={openItemListModal}
            setShowModal={setOpenItemListModal}
            header={"Item List"}
            size={"modal-xl"}
          >
            <ItemListing />
          </Modal>
        </Suspense>
      )}
    </>
  );
};

export default Layout;
