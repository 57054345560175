import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get quality
export const getQuality = createAsyncThunk("quality/getQuality", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getQuality(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all quality
export const getAllQuality = createAsyncThunk("quality/getAllQuality", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllQuality();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("quality/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("quality/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageQuality = createAsyncThunk("quality/getPageQuality", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageQuality(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create quality
export const createQuality = createAsyncThunk("quality/createQuality", async (data, { rejectWithValue }) => {
  const { name, description } = data;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.createQuality(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateQuality = createAsyncThunk("quality/updateQuality", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, description } = values;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.updateQuality(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("quality/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
