import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getDesignComposition,
  getNext,
  createDesignComposition,
  updateDesignComposition,
  handleSearch,
  getDesignCompositionById,
} from "./thunk";
import { v4 } from "uuid";
const initialState = {
  designCompositions: [],
  compositionDetails: [],
  edit: false,
  designComposition: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingDesignComposition: false,
  loadingNext: false,
  loadingUpdated: false,
};

export const designCompositionSlice = createSlice({
  name: "designComposition",
  initialState,
  reducers: {
    compositionDetialAdded: (state, action) => {
      state.compositionDetails = [...state.compositionDetails, action.payload];
    },
    compositionDetialDeleted: (state, action) => {
      state.compositionDetails = state.compositionDetails.filter((detail) => detail.unique !== action.payload);
    },
    clearAllDesignComposition: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingDesignComposition = false;
      state.designComposition = null;
      state.compositionDetails = [];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.designCompositions = [...state.designCompositions, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createDesignComposition.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createDesignComposition.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createDesignComposition.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });

    builder.addCase(updateDesignComposition.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateDesignComposition.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateDesignComposition.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addCase(getDesignCompositionById.pending, (state) => {
      state.loadingUpdated = true;
      state.edit = true;
    });
    builder.addCase(getDesignCompositionById.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.designComposition = action.payload;
      state.compositionDetails = action.payload.designDetails?.map((value) => {
        return { ...value, unique: v4() };
      });
    });
    builder.addCase(getDesignCompositionById.rejected, (state) => {
      state.loadingUpdated = false;
    });

    builder.addMatcher(isAnyOf(getDesignComposition.pending, handleSearch.pending), (state) => {
      state.loadingDesignComposition = true;
    });
    builder.addMatcher(isAnyOf(getDesignComposition.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingDesignComposition = false;
      state.designCompositions = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getDesignComposition.rejected, handleSearch.rejected), (state) => {
      state.loadingDesignComposition = false;
    });
  },
});

export const { compositionDetialAdded, compositionDetialDeleted, clearAllDesignComposition } =
  designCompositionSlice.actions;

export default designCompositionSlice.reducer;
