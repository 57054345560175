import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../Redux/Auth/authSlice";
import alertReducer from "../Redux/Alert/alertSlice";
import layoutReducer from "../Redux/Layout/layoutSlice";
import tabsValueReducer from "../Redux/TabsValue/tabsValueSlice";
import shortcutItemReducer from "../Redux/ShortcutRedux/shortcutItemSlice";

import organizationReducer from "../Pages/Organization/Redux/organizationSlice";

import countryReducer from "../Pages/Country/Redux/countrySlice";

import fiscalSessionADReducer from "../Pages/FiscalSessionAD/Redux/fiscalSessionADSlice";
import fiscalSessionBSReducer from "../Pages/FiscalSessionBS/Redux/fiscalSessionBSSlice";
import userGroupReducer from "../Pages/UserGroup/Redux/userGroupSlice";
import userReducer from "../Pages/User/Redux/userSlice";

import systemSelectionReducer from "../Redux/SystemSelection/systemSelectionSlice";

import notificationReducer from "../Components/CommonPageHeader/redux/notificationSlice.js";

// item reducers
import itemReducer from "../Pages/Item/Redux/itemSlice.js";
import unitReducer from "../Pages/Unit/Redux/unitSlice";
import materialTypeReducer from "../Pages/MaterialType/Redux/materialTypeSlice";
import qualityReducer from "../Pages/Quality/Redux/qualitySlice";
import carpetTypeReducer from "../Pages/CarpetType/Redux/carpetTypeSlice";
import itemStockLimitReducer from "../Pages/ItemStockLimit/Redux/itemStockLimitSlice";

// party reducers
import customerReducer from "../Pages/Customer/Redux/customerSlice";
import supplierReducer from "../Pages/Supplier/Redux/supplierSlice";

// purchase reducers
import purchaseOrderReducer from "../Pages/PurchaseOrder/Redux/purchaseOrderSlice";
import purchaseReducer from "../Pages/Purchase/Redux/purchaseSlice";
import purchaseReturnReducer from "../Pages/PurchaseReturn/Redux/purchaseReturnSlice";

// stock reducers
import stockAdditionReducer from "../Pages/StockAddition/Redux/stockAdditionSlice";
import stockSubtractionReducer from "../Pages/StockSubtraction/Redux/stockSubtractionSlice";
import stockAnalysisReducer from "../Pages/StockAnalysis/Redux/stockAnalysisSlice";
import itemLedgerReducer from "../Pages/ItemLedger/Redux/itemLedgerSlice";
// customer order
import customerOrderReducer from "../Pages/CustomerOrder/Redux/customerOrderSlice";
import saleReducer from "../Pages/Sale/Redux/saleSlice";

// production  reducers
import designCompositionReducer from "../Pages/DesignComposition/Redux/designCompositionSlice";
import dyeingReducer from "../Pages/Dyeing/Redux/dyeingSlice";
import weavingReducer from "../Pages/Weaving/Redux/weavingSlice";
import dyeHouseReducer from "../Pages/DyeHouse/Redux/dyeHouseSlice";

// reports
import weavingReportReducer from "../Pages/Reports/WeavingReport/Redux/weavingReportSlice";
import dyeingReportReducer from "../Pages/Reports/DyeingReport/Redux/dyeingReportSlice";
import customerOrderReportReducer from "../Pages/Reports/CustomerOrderReport/Redux/customerOrderReportSlice";
import dayBookReportReducer from "../Pages/Reports/DayBookReport/Redux/dayBookReportSlice";
import payableReportReducer from "../Pages/Reports/PayableReport/Redux/payableReportSlice";
import receivableReportReducer from "../Pages/Reports/ReceivableReport/Redux/receivableReportSlice";

// dashboard reducer
import dashboardReducer from "../Pages/Dashboard/Redux/dashboardSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  alert: alertReducer,
  layout: layoutReducer,
  tabsValue: tabsValueReducer,
  systemSelection: systemSelectionReducer,
  shortcutItem: shortcutItemReducer,

  organization: organizationReducer,

  country: countryReducer,

  ad: fiscalSessionADReducer,
  bs: fiscalSessionBSReducer,
  user: userReducer,
  userGroup: userGroupReducer,

  notification: notificationReducer,

  // item reducer
  item: itemReducer,
  materialType: materialTypeReducer,
  unit: unitReducer,
  quality: qualityReducer,
  carpetType: carpetTypeReducer,
  itemStockLimit: itemStockLimitReducer,

  // party reducer
  customer: customerReducer,
  supplier: supplierReducer,

  // purchase reducer
  purchaseOrder: purchaseOrderReducer,
  purchase: purchaseReducer,
  purchaseReturn: purchaseReturnReducer,

  // stock addition reducer
  stockAddition: stockAdditionReducer,
  stockSubtraction: stockSubtractionReducer,
  stockAnalysis: stockAnalysisReducer,
  itemLedger: itemLedgerReducer,

  // customer order state
  customerOrder: customerOrderReducer,
  sale: saleReducer,

  // production reducer
  designComposition: designCompositionReducer,
  dyeing: dyeingReducer,
  weaving: weavingReducer,
  dyeHouse: dyeHouseReducer,

  // reports
  weavingReport: weavingReportReducer,
  dyeingReport: dyeingReportReducer,
  customerOrderReport: customerOrderReportReducer,
  dayBookReport: dayBookReportReducer,
  payableReport: payableReportReducer,
  receivableReport: receivableReportReducer,

  // dashboard reducer
  dashboard: dashboardReducer,
});

export default rootReducer;
