import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get unit
export const getUnit = createAsyncThunk("unit/getUnit", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getUnit(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all unit
export const getAllUnit = createAsyncThunk("unit/getAllUnit", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllUnit();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("unit/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("unit/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageUnit = createAsyncThunk("unit/getPageUnit", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageUnit(number, postsPerPage);
    return data;
  } catch (error) {
    return  rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create unit
export const createUnit = createAsyncThunk("unit/createUnit", async (data, { rejectWithValue }) => {
  const { name, description } = data;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.createUnit(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateUnit = createAsyncThunk("unit/updateUnit", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, description } = values;
  try {
    const body = JSON.stringify({ name, description });
    const { data } = await API.updateUnit(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("unit/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
