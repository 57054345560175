import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getReceivableReport, getNext, handleSearch } from "./thunk";

const initialState = {
  receivables: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingReceivable: false,
};

export const receivableReportSlice = createSlice({
  name: "receivableReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.receivables = [...state.receivables, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getReceivableReport.pending, handleSearch.pending), (state) => {
      state.loadingReceivable = true;
    });
    builder.addMatcher(isAnyOf(getReceivableReport.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingReceivable = false;
      state.receivables = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getReceivableReport.rejected, handleSearch.rejected), (state) => {
      state.loadingReceivable = false;
    });
  },
});

export const {} = receivableReportSlice.actions;

export default receivableReportSlice.reducer;
