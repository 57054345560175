import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get itemStockLimit
export const getItemStockLimit = createAsyncThunk(
  "itemStockLimit/getItemStockLimit",
  async (postsPerPage, { rejectWithValue }) => {
    try {
      const { data } = await API.getItemStockLimit(postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all itemStockLimit
export const getAllItemStockLimit = createAsyncThunk(
  "itemStockLimit/getAllItemStockLimit",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllItemStockLimit();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("itemStockLimit/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("itemStockLimit/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageItemStockLimit = createAsyncThunk(
  "itemStockLimit/getPageItemStockLimit",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageItemStockLimit(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create itemStockLimit
export const createItemStockLimit = createAsyncThunk(
  "itemStockLimit/createItemStockLimit",
  async (data, { rejectWithValue }) => {
    const { item, minimumStock, safetyStock } = data;
    try {
      const body = JSON.stringify({
        item,
        minimumStock,
        safetyStock,
      });
      const { data } = await API.createItemStockLimit(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
export const updateItemStockLimit = createAsyncThunk(
  "itemStockLimit/updateItemStockLimit",
  async (data, { rejectWithValue }) => {
    const { id, values } = data;
    const { item, minimumStock, safetyStock } = values;
    try {
      const body = JSON.stringify({
        item,
        minimumStock,
        safetyStock,
      });
      const { data } = await API.updateItemStockLimit(id, body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk("itemStockLimit/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
