import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get customerOrderReport
export const getCustomerOrderReport = createAsyncThunk(
  "customerOrderReport/getCustomerOrderReport",
  async ({ postsPerPage, startDate, endDate, customer, status }, { rejectWithValue }) => {
    try {
      const { data } = await API.getCustomerOrderReport({ postsPerPage, startDate, endDate, customer, status });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all customerOrderReport
export const getAllCustomerOrderReport = createAsyncThunk(
  "customerOrderReport/getAllCustomerOrderReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllCustomerOrderReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk(
  "customerOrderReport/getPrevious",
  async (previous, { rejectWithValue }) => {
    try {
      const { data } = await API.getPrevious(previous);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get next
export const getNext = createAsyncThunk("customerOrderReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageCustomerOrderReport = createAsyncThunk(
  "customerOrderReport/getPageCustomerOrderReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageCustomerOrderReport(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "customerOrderReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ search, postsPerPage, startDate, endDate, customer, status });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
