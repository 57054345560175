import axiosInstance from "../../../../Utils/axios";

//obtaining the paginated data
export const getDayBookReport = ({
  postsPerPage,
  startDate = "",
  endDate = "",
  item = "",
  customer = "",
  supplier = "",
  status = "",
}) =>
  axiosInstance.get(
    `api/v1/report/day-book?offset=0&limit=${postsPerPage}&ordering=-id&start_date=${startDate}&end_date=${endDate}&item=${item}&customer=${customer}&status=${status}&supplier=${supplier}`
  );

//obtaining all fiscal sessions
export const getAllDayBookReport = () => axiosInstance.get(`api/v1/report/day-book?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageDayBookReport = (number, postsPerPage) =>
  axiosInstance.get(`api/v1/report/day-book?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`);

//searching function
export const handleSearch = ({
  search,
  postsPerPage,
  startDate = "",
  endDate = "",
  item = "",
  customer = "",
  supplier = "",
  status = "",
}) =>
  axiosInstance.get(
    `api/v1/report/day-book?offset=0&limit=${postsPerPage}&search=${search}&start_date=${startDate}&end_date=${endDate}&item=${item}&customer=${customer}&status=${status}&supplier=${supplier}`
  );
