import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getCarpetType,
  getNext,
  createCarpetType,
  updateCarpetType,
  handleSearch,
} from "./thunk";

const initialState = {
  carpetTypes: [],
  edit: false,
  carpetType: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingCarpetType: false,
  loadingNext: false,
};

export const carpetTypeSlice = createSlice({
  name: "carpetType",
  initialState,
  reducers: {
    carpetTypeEditSuccess: (state, action) => {
      state.edit = true;
      state.carpetType = state.carpetTypes.find((carpetType) => carpetType.id === action.payload);
    },
    clearAllCarpetType: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingCarpetType = false;
      state.carpetType = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.carpetTypes = [...state.carpetTypes, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createCarpetType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createCarpetType.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createCarpetType.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateCarpetType.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateCarpetType.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateCarpetType.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getCarpetType.pending, handleSearch.pending), (state) => {
      state.loadingCarpetType = true;
    });
    builder.addMatcher(isAnyOf(getCarpetType.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingCarpetType = false;
      state.carpetTypes = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getCarpetType.rejected, handleSearch.rejected), (state) => {
      state.loadingCarpetType = false;
    });
  },
});

export const { carpetTypeEditSuccess, clearAllCarpetType } = carpetTypeSlice.actions;

export default carpetTypeSlice.reducer;
