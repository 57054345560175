import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get dayBookReport
export const getDayBookReport = createAsyncThunk(
  "dayBookReport/getDayBookReport",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier, item }, { rejectWithValue }) => {
    try {
      const { data } = await API.getDayBookReport({
        postsPerPage,
        startDate: startDate !== "" ? startDate : new Date().toISOString().substring(0, 10),
        endDate,
        customer,
        status,
        supplier,
        item,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all dayBookReport
export const getAllDayBookReport = createAsyncThunk(
  "dayBookReport/getAllDayBookReport",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllDayBookReport();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("dayBookReport/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("dayBookReport/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDayBookReport = createAsyncThunk(
  "dayBookReport/getPageDayBookReport",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageDayBookReport(number, postsPerPage);
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// handle search
export const handleSearch = createAsyncThunk(
  "dayBookReport/handleSearch",
  async ({ postsPerPage, startDate, endDate, customer, status, supplier, item, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({
        search,
        postsPerPage,
        startDate: startDate !== "" ? startDate : new Date().toISOString().substring(0, 10),
        endDate: endDate !== "" ? endDate : new Date().toISOString().substring(0, 10),
        customer,
        status,
        supplier,
        item,
      });
      return data;
    } catch (error) {
      return  rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
