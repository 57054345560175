import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get dyeHouse
export const getDyeHouse = createAsyncThunk("dyeHouse/getDyeHouse", async (postsPerPage, { rejectWithValue }) => {
  try {
    const { data } = await API.getDyeHouse(postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get all dyeHouse
export const getAllDyeHouse = createAsyncThunk("dyeHouse/getAllDyeHouse", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllDyeHouse();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("dyeHouse/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("dyeHouse/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageDyeHouse = createAsyncThunk("dyeHouse/getPageDyeHouse", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageDyeHouse(number, postsPerPage);
    return data;
  } catch (error) {
    return  rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// create dyeHouse
export const createDyeHouse = createAsyncThunk("dyeHouse/createDyeHouse", async (data, { rejectWithValue }) => {
  const { name, address, phoneNumber, panOrVatNumber } = data;
  try {
    const body = JSON.stringify({ name, address, phoneNumber, panOrVatNumber });
    const { data } = await API.createDyeHouse(body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
export const updateDyeHouse = createAsyncThunk("dyeHouse/updateDyeHouse", async (data, { rejectWithValue }) => {
  const { id, values } = data;
  const { name, address, phoneNumber, panOrVatNumber } = values;
  try {
    const body = JSON.stringify({ name, address, phoneNumber, panOrVatNumber });
    const { data } = await API.updateDyeHouse(id, body);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk("dyeHouse/handleSearch", async (data, { rejectWithValue }) => {
  const { search, postsPerPage } = data;
  try {
    const { data } = await API.handleSearch(search, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
