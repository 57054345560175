import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getCustomerOrderReport, getNext, handleSearch } from "./thunk";

const initialState = {
  customerOrders: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingCustomerOrder: false,
};

export const customerOrderReportSlice = createSlice({
  name: "customerOrderReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.customerOrders = [...state.customerOrders, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getCustomerOrderReport.pending, handleSearch.pending), (state) => {
      state.loadingCustomerOrder = true;
    });
    builder.addMatcher(isAnyOf(getCustomerOrderReport.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingCustomerOrder = false;
      state.customerOrders = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getCustomerOrderReport.rejected, handleSearch.rejected), (state) => {
      state.loadingCustomerOrder = false;
    });
  },
});

export const {} = customerOrderReportSlice.actions;

export default customerOrderReportSlice.reducer;
