import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import {
  getMaterialType,
  getNext,
  createMaterialType,
  updateMaterialType,
  handleSearch,
} from "./thunk";

const initialState = {
  materialTypes: [],
  edit: false,
  materialType: null,
  count: null,
  next: null,
  previous: null,
  loading: false,
  loadingUpdated: false,
  loadingMaterialType: false,
  loadingNext: false,
};

export const materialTypeSlice = createSlice({
  name: "materialType",
  initialState,
  reducers: {
    materialTypeEditSuccess: (state, action) => {
      state.edit = true;
      state.materialType = state.materialTypes.find((materialType) => materialType.id === action.payload);
    },
    clearAllMaterialType: (state) => {
      state.edit = false;
      state.loading = false;
      state.loadingUpdated = false;
      state.loadingMaterialType = false;
      state.materialType = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.materialTypes = [...state.materialTypes, ...action.payload.results];
      state.next = action.payload.next;
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addCase(createMaterialType.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createMaterialType.fulfilled, (state, action) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(createMaterialType.rejected, (state) => {
      state.loading = false;
      state.edit = false;
    });
    builder.addCase(updateMaterialType.pending, (state) => {
      state.loadingUpdated = true;
    });
    builder.addCase(updateMaterialType.fulfilled, (state, action) => {
      state.loadingUpdated = false;
      state.edit = false;
    });
    builder.addCase(updateMaterialType.rejected, (state) => {
      state.loadingUpdated = false;
    });
    builder.addMatcher(isAnyOf(getMaterialType.pending, handleSearch.pending), (state) => {
      state.loadingMaterialType = true;
    });
    builder.addMatcher(isAnyOf(getMaterialType.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingMaterialType = false;
      state.materialTypes = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getMaterialType.rejected, handleSearch.rejected), (state) => {
      state.loadingMaterialType = false;
    });
  },
});

export const { materialTypeEditSuccess, clearAllMaterialType } = materialTypeSlice.actions;

export default materialTypeSlice.reducer;
