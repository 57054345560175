import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get numeric data for dashboard
export const getNumericData = createAsyncThunk("dashboard/getNumericData", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getNumericData();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get numeric finance data for dashboard
export const getNumericFinanceData = createAsyncThunk(
  "dashboard/getNumericFinanceData",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getNumericFinanceData();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get purchase line chart data
export const getPurchaseLineChartData = createAsyncThunk(
  "dashboard/getPurchaseLineChartData",
  async (filter, { rejectWithValue }) => {
    
    try {
      const { data } = await API.getPurchaseLineChartData(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get sale line chart data
export const getSaleLineChartData = createAsyncThunk(
  "dashboard/getSaleLineChartData",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getSaleLineChartData(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get dyeing line chart data
export const getDyeingLineChartData = createAsyncThunk(
  "dashboard/getDyeingLineChartData",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getDyeingLineChartData(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// get weaving line chart data
export const getWeavingLineChartData = createAsyncThunk(
  "dashboard/getWeavingLineChartData",
  async (filter, { rejectWithValue }) => {
    try {
      const { data } = await API.getWeavingLineChartData(filter);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
