import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get itemLedger
export const getItemLedger = createAsyncThunk(
  "itemLedger/getItemLedger",
  async ({ postsPerPage, startDate, endDate, operationType, item }, { rejectWithValue }) => {
    try {
      const { data } = await API.getItemLedger({ postsPerPage, startDate, endDate, operationType, item });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all itemLedger
export const getAllItemLedger = createAsyncThunk("itemLedger/getAllItemLedger", async (_, { rejectWithValue }) => {
  try {
    const { data } = await API.getAllItemLedger();
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get previous
export const getPrevious = createAsyncThunk("itemLedger/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("itemLedger/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageItemLedger = createAsyncThunk("itemLedger/getPageItemLedger", async (data, { rejectWithValue }) => {
  const { number, postsPerPage } = data;
  try {
    const { data } = await API.getPageItemLedger(number, postsPerPage);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// handle search
export const handleSearch = createAsyncThunk(
  "itemLedger/handleSearch",
  async ({ postsPerPage, startDate, endDate, operationType, item, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, operationType, item, search });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
