import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getShortcutItem, getNext, handleSearch } from "./thunk";

const initialState = {
  items: [],
  count: null,
  next: null,
  loadingItem: false,
  loadingNext: false,
};

export const shortcutItemSlice = createSlice({
  name: "item",
  initialState,
  reducers: {
    clearItems: (state) => {
      state.items = [];
      state.loadingItem = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.items = [...state.items, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getShortcutItem.pending, handleSearch.pending), (state) => {
      state.loadingItem = true;
    });
    builder.addMatcher(isAnyOf(getShortcutItem.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingItem = false;
      state.items = action.payload.results;
      state.count = action.payload.count;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getShortcutItem.rejected, handleSearch.rejected), (state) => {
      state.loadingItem = false;
    });
  },
});

export const { clearItems } = shortcutItemSlice.actions;

export default shortcutItemSlice.reducer;
