import { createSlice, isAnyOf } from "@reduxjs/toolkit";
import { getDyeingReport, getNext, handleSearch } from "./thunk";

const initialState = {
  dyeings: [],
  count: null,
  next: null,
  previous: null,
  loadingNext: false,
  loadingDyeing: false,
};

export const dyeingReportSlice = createSlice({
  name: "dyeingReport",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNext.pending, (state) => {
      state.loadingNext = true;
    });
    builder.addCase(getNext.fulfilled, (state, action) => {
      state.loadingNext = false;
      state.next = action.payload.next;
      state.dyeings = [...state.dyeings, ...action.payload.results];
    });
    builder.addCase(getNext.rejected, (state) => {
      state.loadingNext = false;
    });
    builder.addMatcher(isAnyOf(getDyeingReport.pending, handleSearch.pending), (state) => {
      state.loadingDyeing = true;
    });
    builder.addMatcher(isAnyOf(getDyeingReport.fulfilled, handleSearch.fulfilled), (state, action) => {
      state.loadingDyeing = false;
      state.dyeings = action.payload.results;
      state.count = action.payload.count;
      state.previous = action.payload.previous;
      state.next = action.payload.next;
    });
    builder.addMatcher(isAnyOf(getDyeingReport.rejected, handleSearch.rejected), (state) => {
      state.loadingDyeing = false;
    });
  },
});

export const {} = dyeingReportSlice.actions;

export default dyeingReportSlice.reducer;
