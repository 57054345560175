import { createAsyncThunk } from "@reduxjs/toolkit";
import * as API from "./api";

// get stockAddition
export const getStockAddition = createAsyncThunk(
  "stockAddition/getStockAddition",
  async ({ postsPerPage, startDate, endDate }, { rejectWithValue }) => {
    try {
      const { data } = await API.getStockAddition({ postsPerPage, startDate, endDate });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get all stockAddition
export const getAllStockAddition = createAsyncThunk(
  "stockAddition/getAllStockAddition",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await API.getAllStockAddition();
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// get previous
export const getPrevious = createAsyncThunk("stockAddition/getPrevious", async (previous, { rejectWithValue }) => {
  try {
    const { data } = await API.getPrevious(previous);
    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});
// get next
export const getNext = createAsyncThunk("stockAddition/getNext", async (next, { rejectWithValue }) => {
  try {
    const { data } = await API.getNext(next);

    return data;
  } catch (error) {
    return rejectWithValue(error?.response?.data?.errors[0]?.error);
  }
});

// get particular page
export const getPageStockAddition = createAsyncThunk(
  "stockAddition/getPageStockAddition",
  async (data, { rejectWithValue }) => {
    const { number, postsPerPage } = data;
    try {
      const { data } = await API.getPageStockAddition(number, postsPerPage);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);

// create stockAddition
export const createStockAddition = createAsyncThunk(
  "stockAddition/createStockAddition",
  async (updated, { rejectWithValue, dispatch }) => {
    const { item, quantity, isAddition, reason, colorCode, type } = updated;
    try {
      const body = JSON.stringify({ item, quantity, isAddition, reason, colorCode, type });
      const { data } = await API.createStockAddition(body);
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
// handle search
export const handleSearch = createAsyncThunk(
  "stockAddition/handleSearch",
  async ({ postsPerPage, startDate, endDate, search }, { rejectWithValue }) => {
    try {
      const { data } = await API.handleSearch({ postsPerPage, startDate, endDate, search });
      return data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.errors[0]?.error);
    }
  }
);
