import axiosInstance from "../../../Utils/axios";

//obtaining the paginated data
export const getItemStockLimit = (postsPerPage) =>
  axiosInstance.get(`api/v1/item/item-stock-limit?offset=0&limit=${postsPerPage}&ordering=-id`);

//obtaining all fiscal sessions
export const getAllItemStockLimit = () => axiosInstance.get(`api/v1/item/item-stock-limit?ordering=-id`);

//obtaining the previous page data from paginated data
export const getPrevious = (previous) => axiosInstance.get(previous);

//obtaining the next page data from paginated data
export const getNext = (next) => axiosInstance.get(next);

//obtaining the particular page data from paginated data
export const getPageItemStockLimit = (number, postsPerPage) =>
  axiosInstance.get(
    `api/v1/item/item-stock-limit?offset=${(number - 1) * postsPerPage}&limit=${postsPerPage}&ordering=-id`
  );

//creating function
export const createItemStockLimit = (body) => axiosInstance.post(`api/v1/item/item-stock-limit`, body);
//updating function
export const updateItemStockLimit = (id, body) => axiosInstance.patch(`api/v1/item/item-stock-limit/${id}`, body);

//searching function
export const handleSearch = (search, postsPerPage) =>
  axiosInstance.get(`api/v1/item/item-stock-limit?offset=0&limit=${postsPerPage}&search=${search}`);
